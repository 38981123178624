export const boxPadding = 2
export const boxBorderRadius = 5
export const buttonSx = {
	borderRadius: 50,
	minWidth: 0,
	minHeight: 0,
	width: 60,
	height: 60,
	fontSize: 20,
}
